import React, { useContext, useEffect } from "react"
import Layout from "../../Layout"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { BiHappyAlt } from "react-icons/bi"
import { CartContext } from "../../contexts/Cart"

const CartSuccess = () => {
  const {
    actions: { clearCart },
  } = useContext(CartContext)

  useEffect(() => {
    clearCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Seo title="Success" noIndex />

      <Breadcrumbs title={"Success"} />

      <div className="container my-4 py-4">
        <div className="row">
          <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center">
            <span className={"text-primary"}>
              <BiHappyAlt size={60} />
            </span>
            <h4 className={"mt-4 text-primary"}>Success.</h4>
            <p>
              We received your order. One of our agents will be in touch with
              you shortly.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CartSuccess
